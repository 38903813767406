import styled from "styled-components";

export const LogoEmp1 = styled.div`
@media screen and (max-width: 766px){


}
@media screen and (min-width: 767px){


}
@media screen and (min-width: 768px){

}
@media screen and (min-width: 992px){

}
@media screen and (min-width: 1200px){

  
}
`

export const LogoEmp2 = styled.div`
@media screen and (max-width: 766px){
height: 80%;
width: 40vw;
}
@media screen and (min-width: 767px){
    display: none;
}
`

export const Container = styled.div`
background-color: rgba(36,167,207,255);
height: 9.7vh;
width: 100vw;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
`

export const Sec1 = styled.div`
height: 10vh;
width: 70vw;
background-color: rgba(36,167,207,255);
display: flex;
flex-direction: row;
text-align: center;
align-items: center;
justify-content: space-between;
`

export const Lema = styled.div`
background-color: rgba(36,167,207,255);
@media screen and (max-width: 766px){
    display: none;

}
@media screen and (min-width: 767px){
    height: 100%;
    width: 60vw;
    text-align: center;
    padding-left: 15px;
    margin-right: 10px;
    display: flex ;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;

}
@media screen and (min-width: 768px){
     height: 100%;
    width: 60vw;
    text-align: center;
    padding-left: 15px;
    display: flex ;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
}
@media screen and (min-width: 992px){
    height: 100%;
    width: 60vw;
    text-align: justify;
    padding-left: 15px;
    display: flex ;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
}
@media screen and (min-width: 1200px){
    height: 100%;
    width: 60vw;
    text-align: justify;
    padding-left: 15px;
    display: flex ;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
  
}
`

export const Telefono = styled.div`
background-color: rgba(36,167,207,255);

@media screen and (max-width: 766px){
height: 100%;
width: 40vw;
border-right: 10px solid white;
display: flex;
flex-direction: row;
align-items: center;
justify-content: end;
padding-right: 20px;
}
@media screen and (min-width: 767px){
  height: 100%;
width: 25vw;
border-right: 10px solid white;
display: flex;
flex-direction: row;
align-items: center;
justify-content: end;
padding-right: 20px;
}

@media screen and (min-width: 768px){
height: 100%;
width: 30vw;
border-right: 10px solid white;
display: flex;
flex-direction: row;
align-items: center;
justify-content: end;
padding-right: 50px;
}
@media screen and (min-width: 992px){
height: 100%;
width: 40vw;
border-right: 10px solid white;
display: flex;
flex-direction: row;
align-items: center;
justify-content: end;
padding-right: 50px;
}
@media screen and (min-width: 1200px){
height: 100%;
width: 40vw;
border-right: 10px solid white;
display: flex;
flex-direction: row;
align-items: center;
justify-content: end;
padding-right: 50px;
}
`

export const Sec2 = styled.div`
height: 10vh;
width: 30vw;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;

@media screen and (max-width: 767px){
padding-left: 10px;
}

@media screen and (min-width: 768px){
padding-left: 10px;
}
@media screen and (min-width: 992px){

}
@media screen and (min-width: 1200px){

}
`