import styled from "styled-components";

export const Container = styled.div`
background-color: white;
display: flex;
flex-direction: row;
align-items: center;
width: 100%;
border-bottom: 1px solid gray;
justify-content: center;
@media screen and (max-width: 767px){
height: 8vh;
}

@media screen and (min-width: 768px){
height: 13vh;
}
@media screen and (min-width: 992px){
height: 13vh;
}
@media screen and (min-width: 1200px){
height: 13vh;
}
`

export const LogoEmp = styled.div`

@media screen and (max-width: 767px){
display: none;
}

@media screen and (min-width: 768px){
height: 100%;
width: 30vw;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
}
@media screen and (min-width: 992px){
height: 100%;
width: 30vw;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
}
@media screen and (min-width: 1200px){
height: 100%;
width: 30vw;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
}
`

export const ImagenLogo = styled.img`
border-radius: 100%;
height: 100%;
`