import styled from "styled-components";

export const Container = styled.div`
height: 100%;
width: 100%;
`
export const ContainerImagen = styled.div`
height: max-content;
width: 100%;
display: flex;
flex-direction: column;
`

export const ImagenMobile = styled.img`
@media screen and (max-width: 767px){
display: flex;
}

@media screen and (min-width: 768px){
display: none;
}
`
export const ImagenWeb = styled.img`
@media screen and (max-width: 767px){
display: none;
}

@media screen and (min-width: 768px){
display: flex;
}
`