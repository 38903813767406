import styled from "styled-components";

export const Container = styled.div`
height: 100%;
width: 100%;
`
export const ContainerImagen = styled.div`
height: max-content;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`
export const ImagenMobile = styled.div`
@media screen and (max-width: 766px){
display: flex;
flex-direction: column;
margin-top: 10px;
}
@media screen and (min-width: 767px){
display: none;
}
`
export const ImagenWeb = styled.img`
@media screen and (max-width: 766px){
display: none;

}
@media screen and (min-width: 767px){
display: flex;
height: 100%;
width: 100%;

}
@media screen and (min-width: 768px){

}
@media screen and (min-width: 992px){

}
@media screen and (min-width: 1200px){

  
}
`
export const ContainerImagenMobile = styled.div`
height: 35vh;
width: 80vw;
margin-top:50px;
margin-bottom:20px ;
`
export const ImagenMobile2 = styled.img`
height: 100%;
width: 100%;
`
export const ContainerCheck = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
font-weight: bolder;
`
export const Titulo = styled.div`
text-transform: uppercase;
background-color: rgba(47,69,157,255);
color: white;
font-weight: bolder;
font-size: larger;
padding: 10px;
text-align: center;
`