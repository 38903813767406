import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Inicio } from '../Screens/Pages/Inicio'
import { Contacto } from '../Screens/Pages/Contacto'
import { Clientes } from '../Screens/Pages/Clientes'
import { Servicios } from '../Screens/Pages/Servicios'
import { Error } from '../Screens/Pages/Error'
import { Container } from '../Styles/Body'

export const Body = () => {
  return (
    <>
      <Container>
        <Routes>
          <Route path='/' element={<Inicio />} />
          <Route path='/Servicios' element={<Servicios />} />
          <Route path='/Contacto' element={<Contacto />} />
          <Route path='/Clientes' element={<Clientes />} />
          <Route path='*' element={<Error />} />
        </Routes>
      </Container>
    </>
  )
}
