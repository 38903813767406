import styled from "styled-components";

export const Container = styled.div`
width: 100%;
overflow-y: scroll;
@media screen and (max-width: 767px){
height:78.3vh;
}

@media screen and (min-width: 768px){
height:73.3vh;
}
@media screen and (min-width: 992px){
height:73.3vh;
}
@media screen and (min-width: 1200px){
height:73.3vh;
}

`