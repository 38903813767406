import styled from "styled-components";

export const Container = styled.div`
height: 100%;
width: 100%;

`
export const Col = styled.div`
height: max-content;
width: 100%;
display: flex;
align-items: center;
justify-content: space-around;

@media screen and (max-width: 766px){
flex-direction: column;
margin-top: 20px;
}
@media screen and (min-width: 767px){
flex-direction: row;
margin-top: 50px;
}
`

export const ContenedorTexto = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: justify;
background-color: rgba(47,69,157,255);
border-radius: 5px;
padding: 5px;

p{
    color: white;
    font-size: large;
    font-weight: 900;
}
@media screen and (max-width: 766px){
    width: 80vw;
    margin-bottom: 10px;
}
@media screen and (min-width: 767px){
    width: 40vw;
}
`

export const ContenedorImagen = styled.div`
height: 60vh;
width: 60vw;
@media screen and (max-width: 766px){
    height: 40vh;
    width: 80vw;
    margin-bottom: 10px;
}
@media screen and (min-width: 767px){
    width: 40vw;
}
`
export const Imagen = styled.img`
height: 100%;
width: 100%;
border-radius: 5px;
`