import React from 'react'
import styled from 'styled-components'
import emailjs from '@emailjs/browser'
import { useRef } from 'react'
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'

export const Postulacion = () => {

    const form = useRef()
    const serviceID = 'service_jp6fepy';
    const templateID = 'template_t9bbd5b';
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(serviceID, templateID, form.current, 'bc8-vbEK_Jd6EBKkd')
            .then(() => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                Toast.fire({
                    icon: 'success',
                    title: 'Postulado exitosamente!!!'
                })
                setInterval(() => {
                    window.location.reload()
                }, 3500)
            }, (error) => {
                Swal.fire({
                    title: 'Error:',
                    html: 'Su postulacion no se ha enviado, intentelo mas tarde.',
                    icon: 'error'
                })

            });
    };

    return (
        <>
            <form ref={form} onSubmit={sendEmail} style={{ textAlign: 'center' }}>
                <Titulo>
                    <h3>Postula con nosotros</h3>
                </Titulo>
                <ContForm>
                    <div class="m-1">
                        <label for="user_name" class="form-label">Nombre: </label>
                        <input type="text" class="form-control" name='user_name' id="user_name" />
                    </div>
                    <div class="m-1">
                        <label for="user_rut" class="form-label">Rut: </label>
                        <input class="form-control" name='user_rut' id="user_rut" />
                    </div>
                </ContForm>
                <ContForm>
                    <div class="m-1">
                        <label for="user_phone" class="form-label">Telefono: </label>
                        <input type="number" class="form-control" name='user_phone' id="user_phone" />
                    </div>
                    <div class="m-1">
                        <label for="user_city" class="form-label">Comuna: </label>
                        <input type="text" class="form-control" name='user_city' id="user_city" />
                    </div>
                </ContForm>

                <Comentario class="m-1">
                    <label for="message" class="form-label">Comentario: </label>
                    <textarea rows={5} type="text" class="form-control" name='message' id="message" placeholder='Comentanos si tienes curso OS10, experiencia y por que deberiamos contratarte' />
                </Comentario>
                <button type="submit" id="button" value="Send Email" class="btn btn-primary">Enviar</button>
            </form>
        </>
    )
}

const Titulo = styled.div`
@media screen and (max-width: 600px){
  color: black;
}
@media screen and (min-width: 600px){
  color: white;
}
`

const ContForm = styled.div`
display: flex;
flex-direction: row;
@media screen and (max-width: 600px){
  color: black;
}
@media screen and (min-width: 600px){
  color: white;
}
`
const Comentario = styled.div`
color: white;
margin-bottom: 5px;
@media screen and (max-width: 600px){
  color: black;
}
`