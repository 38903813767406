import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'


export const Navbar = () => {

  return (

    <Navegacion>
      <Botonera>
        <LinkNav to='/'>
          <Text>
            Inicio
          </Text>
        </LinkNav>
        <LinkNav to='/Servicios'>
          <Text>
            Servicios
          </Text>
        </LinkNav>
        <LinkNav to='/Contacto'>
          <Text>
            Contacto
          </Text>
        </LinkNav>
        <LinkNav to='/Clientes'>
          <Text>
            Clientes
          </Text>
        </LinkNav>
      </Botonera>
    </Navegacion>
  )
}

const Navegacion = styled.div`

@media screen and (max-width: 767px){
    display: none;
}

@media screen and (min-width: 768px){
  width: 100%;
  height: 10vh;    

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
@media screen and (min-width: 992px){
  width: 100%;
  height: 10vh;    

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
@media screen and (min-width: 1200px){
  width: 70vw;
  height: 10vh;    

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  
}

`

export const Botonera = styled.nav`
    @media screen and (max-width: 767px){
      display: flex;
      flex-direction: row;
      width: 100%;
      text-align: center;
      justify-content: center;
      a{
          text-decoration: none;
          color: black;
          font-weight: bold;
          color: rgba(47,69,157,255);
      }
      a.active{
          border-bottom: 3px solid rgba(47,69,157,255);
      }
    }
    @media screen and (min-width: 768px){
      display: flex;
      flex-direction: row;
      width: 100%;
      text-align: center;
      justify-content: center;
      a{
          text-decoration: none;
          color: black;
          font-weight: bold;
          color: rgba(47,69,157,255);
      }
      a.active{
          border-bottom: 3px solid rgba(47,69,157,255);
      }
    }
    @media screen and (min-width: 992px){
      display: flex;
        flex-direction: row;
        width: 100%;
        text-align: center;
        justify-content: center;
        margin: 15px;
        a{
            text-decoration: none;
            color: black;
            font-weight: bold;
            color: rgba(47,69,157,255);
        }
        a.active{
            border-bottom: 3px solid rgba(47,69,157,255);
        }
    }
    @media screen and (min-width: 1200px){
      display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 20px;
        a{
            text-decoration: none;
            color: black;
            font-weight: bold;
            margin: 10px;
            color: rgba(47,69,157,255);
        }
        a.active{
            border-bottom: 3px solid rgba(47,69,157,255);
        }
    }
`
export const Text = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 5vh;
    width: max-content;
    text-transform: uppercase;
    font-size: x-large;
`
export const LinkNav = styled(NavLink)`
padding: 10px;
width: max-content;
`