import styled from "styled-components";

export const Container = styled.div`
height: 4vh;
width: 100%;
display: flex;
flex-direction: row;
`
export const Sec1 = styled.div`
width: 50%;
height: 100%;
background-color: rgba(36,167,207,255);
`
export const Sec2 = styled.div`
width: 50%; 
height: 100%;
background-color: rgba(47,69,157,255);
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
`