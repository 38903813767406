import React from 'react'
import styled from 'styled-components'
import emailjs from '@emailjs/browser'
import { useRef } from 'react'
import Swal from 'sweetalert2'

export const ContactoComponent = () => {

    const form = useRef()
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_jp6fepy', 'template_nobqx2i', form.current, 'bc8-vbEK_Jd6EBKkd')
      .then(() => {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        Toast.fire({
          icon: 'success',
          title: 'Contacto enviado con exito!!!'
        })
        setInterval(()=>{
          window.location.reload()
        }, 3500)
      }, (error) => {
        Swal.fire({
          title: 'Error:',
          html: 'Su contacto no ha sido enviado, intentelo mas tarde',
          icon: 'error'
        })
        
      });
  };

  return (
    <>
        <form ref={form} onSubmit={sendEmail} style={{ textAlign: 'center' }} >
              <Titulo>
                <h3>Contactanos</h3>
              </Titulo>
              
              <ContForm>
              <div class="m-1">
                  <label for="nombre" class="form-label">Nombre: </label>
                  <input  type="text" class="form-control" name="user_name" id='user_name' />
                </div>
                <div class="m-1">
                  <label for="email" class="form-label">Email: </label>
                  <input  required type="text" name="user_email" id='user_email' className="form-control" />
                </div>
              </ContForm>
              <ContForm>
                <div class="m-1">
                  <label for="telefono" class="form-label">Telefono: </label>
                  <input  type="number" class="form-control" name="user_phone" id='user_phone' />
                </div>
                <div class="m-1">
                  <label for="ciudad" class="form-label">Ciudad: </label>
                  <input  type="text" class="form-control" name="user_city" id='user_city' />
                </div>
              </ContForm>
              <Comentario class="m-1">
                <label for="comentario" class="form-label">Comentario: </label>
                <textarea  rows={5} type="text" class="form-control" name="message" id='message' />
              </Comentario>
              <button type="submit" class="btn btn-primary" value='Send'>Enviar</button>
            </form>
    </>
  )
}


const Titulo = styled.div`
@media screen and (max-width: 600px){
  color: black;
}
@media screen and (min-width: 600px){
  color: white;
}
`

const ContForm = styled.div`
display: flex;
flex-direction: row;

@media screen and (max-width: 600px){
  color: black;
}
@media screen and (min-width: 600px){
  color: white;
}
`
const Comentario = styled.div`
color: white;
margin-bottom: 5px;
@media screen and (max-width: 600px){
  color: black;
}
`