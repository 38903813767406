import React from 'react'
import { Container, ImagenLogo, LogoEmp } from '../Styles/Menu'
import { Navbar } from './Navbar'
import Logo from '../Image/inout.png'
import { NavbarMobile } from './NavbarMobile'

export const Menu = () => {
  return (
    <Container>
        <LogoEmp>
          <ImagenLogo src={Logo} alt='Logo Empresa'/>
        </LogoEmp>
        <Navbar/>
        <NavbarMobile/>
    </Container>
  )
}
