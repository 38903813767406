import React from 'react'
import { Container, ContainerCheck, ContainerImagenMobile, ImagenMobile, ImagenMobile2, ImagenWeb, Titulo } from '../../Styles/Servicios'
import ImagenFondo1 from '../../Image/ImagenFondo7.png'
import { ContainerImagen } from '../../Styles/Servicios'
import ImagenFondo2 from '../../Image/FondoClientes.png'

export const Servicios = () => {
  return (
    <Container>
      <ContainerImagen>
        <ImagenWeb src={ImagenFondo1} alt='Imagen Fondo' />
        <ImagenMobile>
          <Titulo>
            SERVICIOS
          </Titulo>
          <ContainerImagenMobile>
            <ImagenMobile2 src={ImagenFondo2} alt='Imagen' />
          </ContainerImagenMobile>
          <ContainerCheck>
            <div>
              <svg xmlns="https://www.w3.org/2000/svg" style={{
                margin: '5px'
              }} width="16" height="16" fill="rgba(36,167,207,255)" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              CENTROS Y OPERADORES LOGISTICOS
            </div>
            <div>
              <svg xmlns="https://www.w3.org/2000/svg" style={{
                margin: '5px'
              }} width="16" height="16" fill="rgba(36,167,207,255)" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              BODEGAS
            </div>
            <div>
              <svg xmlns="https://www.w3.org/2000/svg" style={{
                margin: '5px'
              }} width="16" height="16" fill="rgba(36,167,207,255)" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              SECTOR AGRICOLA
            </div>
            <div>
              <svg xmlns="https://www.w3.org/2000/svg" style={{
                margin: '5px'
              }} width="16" height="16" fill="rgba(36,167,207,255)" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              CONDOMINIOS
            </div>
            <div>
              <svg xmlns="https://www.w3.org/2000/svg" style={{
                margin: '5px'
              }} width="16" height="16" fill="rgba(36,167,207,255)" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              CONSTRUCTORAS
            </div>
            <div>
              <svg xmlns="https://www.w3.org/2000/svg" style={{
                margin: '5px'
              }} width="16" height="16" fill="rgba(36,167,207,255)" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              PARQUES INDUSTRIALES
            </div>
            <div>
              <svg xmlns="https://www.w3.org/2000/svg" style={{
                margin: '5px'
              }} width="16" height="16" fill="rgba(36,167,207,255)" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              EVENTOS
            </div>
          </ContainerCheck>
        </ImagenMobile>
      </ContainerImagen>
    </Container>
  )
}
