import React from 'react'
import { Col, Container, ContenedorImagen, ContenedorTexto, Imagen } from '../../Styles/Clientes'
import Fondo1 from '../../Image/FondoClientes3.png'

export const Clientes = () => {
  return (
    <Container>
      <Col>
        <ContenedorImagen>
          <Imagen src={Fondo1} alt='Fondo1' />
        </ContenedorImagen>
        <ContenedorTexto>
          <p>
            En nuestra empresa, consideramos a nuestros clientes como nuestro activo más
            valioso y nos esforzamos constantemente en comprender sus necesidades y deseos
            para poder brindarles soluciones efectivas y personalizadas. Nos enfocamos en
            establecer relaciones a largo plazo con ellos, construyendo una confianza mutua
            y ofreciendo un servicio excepcional que supere sus expectativas en todo momento.
            Nos aseguramos de estar siempre disponibles para ellos, brindándoles asistencia
            y soporte técnico en caso de que lo necesiten. 
            </p>
            <p> Dentro de nuestros cliente, tenemos
            operadores logisticos, condominios, parques industriales y al sector agricolas.
            Tambien prestamos servicios de seguridad a eventos, tanto deportivos como recreativos
          </p>
        </ContenedorTexto>
      </Col>
    </Container>
  )
}
