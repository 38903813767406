import React from 'react'
import { Container, Lema, LogoEmp2, Sec1, Sec2, Telefono } from '../Styles/Header'
import LogoEmpresa from '../Image/inout.png'
import styled from 'styled-components'

export const Header = () => {
    return (
        <Container>
            <Sec1>
                <LogoEmp2>
                    <a href='https://inout.cl/'>
                        <ImagenLogo style={{
                        borderRadius: '100%'
                    }} src={LogoEmpresa} alt='Logo Empresa' />
                    </a>
                    
                </LogoEmp2>
                <Lema>
                    Seguridad y tranquilidad desde 2014.
                    Protegemos a nuestros clientes con soluciones innovadoras y personalizadas.
                </Lema>
                <Telefono>
                    <div style={{
                        marginRight: '15px'
                    }}>
                        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-telephone-outbound-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z" />
                        </svg>
                    </div>
                    <div style={{
                        color: 'white',
                        fontWeight: 'Bold'
                    }}>
                        <a style={{
                            textDecoration: 'none',
                            color: 'white'
                        }} href='tel:+56954039832'>+56954039832</a>
                    </div>
                </Telefono>
            </Sec1>
            <Sec2>
                <div style={{
                    marginRight: '7px'
                }}>
                    <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                    </svg>
                </div>
                <div style={{
                    color: 'white',
                    fontWeight: 'Bold'
                }}>
                    <DirWeb>
                        Volcán Licancabur 391,
                        Pudahuel.
                    </DirWeb>
                    <DirMob>
                        Volcán Licancabur 391,
                        Pudahuel.
                    </DirMob>
                </div>
            </Sec2>
        </Container>
    )
}


const DirWeb = styled.div`
@media screen and (max-width: 766px){
    display: none;
}
@media screen and (min-width: 767px){
    display: flex;
}
`

const DirMob = styled.div`
@media screen and (max-width: 766px){
    display: flex;
    font-size: small;
    text-align: center;
}
@media screen and (min-width: 767px){
    display: none;
}
`

const ImagenLogo = styled.img`
height: 90%;
width: 50%;
`