import styled from "styled-components";

export const Container = styled.div`
height: max-content;
width: 100%;
display: flex;
align-items: center;
justify-content: space-around;
text-align: center;
@media screen and (max-width: 600px){
flex-direction: column;
}
@media screen and (min-width: 600px){
flex-direction: column;
}
`
export const ContactoMobile = styled.div`

display: flex;
flex-direction: row;
margin: 50px;
justify-content: space-around;
@media screen and (max-width: 600px){
flex-direction: column;
}
@media screen and (min-width: 600px){
flex-direction: row;
width: 100%;
}
`

export const Rrss = styled.div`
@media screen and (max-width: 600px){
display: flex;
flex-direction: row;
}
@media screen and (min-width: 600px){
display: flex;
flex-direction: row;
}
`
export const ContainerRrss = styled.div`
@media screen and (max-width: 600px){
margin: 15px;
}
@media screen and (min-width: 600px){
margin: 20px
}
`
export const Vinculos = styled.a`
text-decoration: none;
color: rgba(47,69,157,255);
:hover{
    color:rgba(36,167,207,255); 
}
`