import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'


export const NavbarMobile = () => {

    return (

        <Navegacion>
            <Botonera>
                <LinkNav to='/'>
                    <Text>
                        <svg xmlns="https://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-house-door-fill" viewBox="0 0 16 16">
                            <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z" />
                        </svg>
                        <div>Home</div>
                    </Text>
                </LinkNav>
                <LinkNav to='/Servicios'>
                    <Text>
                        <svg xmlns="https://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-briefcase-fill" viewBox="0 0 16 16">
                            <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5z" />
                            <path d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z" />
                        </svg>
                        <div>Servicios</div>
                    </Text>
                </LinkNav>
                <LinkNav to='/Contacto'>
                    <Text>
                        <svg xmlns="https://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-telephone-plus-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5z" />
                        </svg>
                        <div>Contacto</div>
                    </Text>
                </LinkNav>
                <LinkNav to='/Clientes'>
                    <Text>
                        <svg xmlns="https://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-buildings-fill" viewBox="0 0 16 16">
                            <path d="M15 .5a.5.5 0 0 0-.724-.447l-8 4A.5.5 0 0 0 6 4.5v3.14L.342 9.526A.5.5 0 0 0 0 10v5.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V14h1v1.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5V.5ZM2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-1 2v1H2v-1h1Zm1 0h1v1H4v-1Zm9-10v1h-1V3h1ZM8 5h1v1H8V5Zm1 2v1H8V7h1ZM8 9h1v1H8V9Zm2 0h1v1h-1V9Zm-1 2v1H8v-1h1Zm1 0h1v1h-1v-1Zm3-2v1h-1V9h1Zm-1 2h1v1h-1v-1Zm-2-4h1v1h-1V7Zm3 0v1h-1V7h1Zm-2-2v1h-1V5h1Zm1 0h1v1h-1V5Z" />
                        </svg>
                        <div>Clientes</div>
                    </Text>
                </LinkNav>
            </Botonera>
        </Navegacion>
    )
}


const Navegacion = styled.div`

@media screen and (max-width: 767px){
    display: flex;
    flex-direction: row;
}

@media screen and (min-width: 768px){
  display: none;
}
@media screen and (min-width: 992px){
  display: none;
}
@media screen and (min-width: 1200px){
  display: none;
}

`

export const Botonera = styled.nav`
    @media screen and (max-width: 767px){
      display: flex;
      flex-direction: row;
      width: 100%;
      text-align: center;
      justify-content: center;
      a{
          text-decoration: none;
          color: black;
          font-weight: bold;
          color: rgba(47,69,157,255);
      }
      a.active{
          border-bottom: 3px solid rgba(47,69,157,255);
      }
    }
    @media screen and (min-width: 768px){
      display: flex;
      flex-direction: row;
      width: 100%;
      text-align: center;
      justify-content: center;
      a{
          text-decoration: none;
          color: black;
          font-weight: bold;
          color: rgba(47,69,157,255);
      }
      a.active{
          border-bottom: 3px solid rgba(47,69,157,255);
      }
    }
    @media screen and (min-width: 992px){
      display: flex;
        flex-direction: row;
        width: 100%;
        text-align: center;
        justify-content: center;
        margin: 15px;
        a{
            text-decoration: none;
            color: black;
            font-weight: bold;
            color: rgba(47,69,157,255);
        }
        a.active{
            border-bottom: 3px solid rgba(47,69,157,255);
        }
    }
    @media screen and (min-width: 1200px){
      display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 20px;
        a{
            text-decoration: none;
            color: black;
            font-weight: bold;
            margin: 10px;
            color: rgba(47,69,157,255);
        }
        a.active{
            border-bottom: 3px solid rgba(47,69,157,255);
        }
    }
`
export const Text = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    
    @media screen and (max-width: 767px){
        flex-direction: column;
    }

    @media screen and (min-width: 768px){
        flex-direction: row;
        height: 5vh;
        font-size: x-large;
    }
    @media screen and (min-width: 992px){
    }
    @media screen and (min-width: 1200px){
    }

`
export const LinkNav = styled(NavLink)`

@media screen and (max-width: 767px){
    padding: 10px;
}

@media screen and (min-width: 768px){
    
    width: max-content;
}
@media screen and (min-width: 992px){
}
@media screen and (min-width: 1200px){
}
`