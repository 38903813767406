import './App.css';
import { Home } from './Screens/Home';


function App() {
  return (
    <Home/>
  )
}

export default App;
