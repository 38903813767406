import React from 'react'
import { Container } from '../Styles/Home'
import { Header } from '../Components/Header'
import { Body } from '../Components/Body'
import { Footer } from '../Components/Footer'
import { Menu } from '../Components/Menu'

export const Home = () => {
  return (
    <>
      <Container>
        <Header/>
        <Menu/>
        <Body/>
        <Footer/>
      </Container>
    </>
  )
}
