import React from 'react'
import styled from 'styled-components'

export const Maps = () => {
    return (
        <div>
            <Mapa src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3331.4438608883634!2d-70.7791704244259!3d-33.38558247341517!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c0e8ad90b773%3A0xfb212baba96d7b1e!2sInout%20Seguridad%20SPA!5e0!3m2!1ses-419!2scl!4v1681864188594!5m2!1ses-419!2scl"
                allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='Ubicacion-oficina' />
        </div>
    )
}

const Mapa = styled.iframe`
border-radius: 5px;
border: 5px solid rgba(47, 69, 157, 255);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
@media screen and (max-width: 600px){
  height: 40vh;
    width: 80vw;
}
@media screen and (min-width: 600px){
    height: 60vh;
    width: 40vw;
    
}
`
