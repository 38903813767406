import React from 'react'
import { Container, ContainerImagen, ImagenMobile, ImagenWeb } from '../../Styles/Inicio'
import Imagen5 from '../../Image/ImagenFondo5.png'
import Imagen6 from '../../Image/ImagenFondo6.png'
import Imagen4 from '../../Image/ImagenFondo4.png'
import Imagen1 from '../../Image/ImagenFondo1.png'
import Imagen2 from '../../Image/ImagenFondo2.png'
import Imagen3 from '../../Image/ImagenFondo3.png'
import { Container as Contenedor } from '../../Styles/Footer'
import { Sec1, Sec2 } from '../../Styles/Footer'
import styled from 'styled-components'

export const Inicio = () => {
  return (
    <Container>
      <ContainerImagen>
        <Mobile>
          <Contenedor>
            <Sec1 style={{
              color: 'white',
              textAlign: 'center'
            }}>
              Guardias de seguridad
            </Sec1>
            <Sec2 style={{
              fontSize: 'x-small',
              textAlign: 'center',
              color: 'white'
            }}>
              Personal acreditado y certificado por OS10
            </Sec2>
          </Contenedor>
        </Mobile>
        <ImagenWeb src={Imagen4} alt='Imagen Fondo' />
        <ImagenMobile src={Imagen1} alt='Imagen Fondo' />
        <Mobile>
          <Contenedor>
            <Sec1 style={{
              color: 'white',
              textAlign: 'center'
            }}>
              CCTV
            </Sec1>
            <Sec2 style={{
              fontSize: 'x-small',
              textAlign: 'center',
              color: 'white'
            }}>
              Monitoreamos, controlamos y verificamos su sistema CCTV
            </Sec2>
          </Contenedor>
        </Mobile>
        <ImagenWeb src={Imagen5} alt='Imagen Fondo' />
        <ImagenMobile src={Imagen2} alt='Imagen Fondo' />
        <Mobile>
          <Contenedor>
            <Sec1 style={{
              color: 'white',
              textAlign: 'center'
            }}>
              Rondas Vehiculares
            </Sec1>
            <Sec2 style={{
              fontSize: 'x-small',
              textAlign: 'center',
              color: 'white'
            }}>
              Rondas especificas por sectores criticos
            </Sec2>
          </Contenedor>
        </Mobile>
        <ImagenWeb src={Imagen6} alt='Imagen Fondo' />
        <ImagenMobile src={Imagen3} alt='Imagen Fondo' />
      </ContainerImagen>
    </Container>
  )
}

const Mobile = styled.div`
@media screen and (max-width: 767px){
display: flex;
}
@media screen and (min-width: 767px){
display: none;
}
`